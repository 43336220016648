<script lang="ts">
	import { classes } from '@thearc-hq/ui-kit/utils';

	export let open = false;
	export let contentClassName = '';
	let className = '';
	export { className as class };

	let delayedOpen = open;

	$: {
		if (open) {
			delayedOpen = true;
		} else {
			setTimeout(() => {
				delayedOpen = false;
			}, 300);
		}
	}
</script>

<div class={classes('relative', className)}>
	<div class={contentClassName}>
		<slot />
	</div>

	<div
		class={classes(
			'transition transform duration-300',
			open ? '' : 'opacity-0 -translate-y-2',
			delayedOpen ? '' : 'h-0 hidden overflow-auto'
		)}
	>
		<slot name="content-open" />
	</div>
</div>
